import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
// import { courses } from '../../assets/JSON';
import './Courses.css'
import { Anchor, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Courses = props => {
const[searched, setSearched] = useState('');
const courses = useSelector(state=>state.courses.items);
const navigate = useNavigate();

useEffect(()=>{
const time = setInterval(() => {
    let a = JSON.parse(localStorage.getItem('searched'))
        setSearched(a)
    }, 500);
    return ()=>clearInterval(time)
},[JSON.parse(localStorage.getItem('searched'))]);

useEffect(()=>{
    localStorage.removeItem('searched')
},[])

let  filtered = []
if(searched?.length>0){
     filtered = courses.filter(filt=>filt?.description?.toLowerCase().includes(searched?.toLowerCase()))
}else{
    filtered = courses
}
    return(<div  className='courses-container'>
  {filtered?.length>0?
    <>
<h4 className="courses-title">Available Courses</h4>
<Row sm={4} className='courses-cards'>
{
   filtered?.map((item,index)=>{
    return(
<Card key={index} className='courses-card'>
<CardImg src={item?.src} alt={item?.title} className='courses-card-image' />
<CardBody className="courses-card-body">
  <CardTitle className="courses-card-title">{item?.title}</CardTitle>
  {/* <CardText className="courses-card-text-courses">{item?.description}</CardText> */}
  <CardText className="courses-card-text-courses">By {item?.owner}</CardText>
  <Button onClick={()=>navigate(`/course/${item?._id}`)}  className='btn checkout-btn'>Get started</Button>
</CardBody>
</Card>)})
}
</Row>
    </>
  :
  <Row>
  <Container className='container'>
    <div className='page404'>
    <h4 className="title404">404</h4>
    <p className='content404'>No content found for your search</p>
    </div>
    </Container>
</Row>}
</div>)
};

Courses.propTypes = {};

export { Courses };