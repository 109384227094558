import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

const PaymentSuccess = props => {
    const{courseTitle,venue} = useParams()
    const user = useSelector(state=>state.auth.user);
    return <Container className='payment-succes'>
<p>You have successfully registered {courseTitle} and an email containing your class schedule was sent to {user?.email}.</p>
    </Container>;
};

PaymentSuccess.propTypes = {};

export { PaymentSuccess };