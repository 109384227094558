import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:[],
    status:''
}
export const getEnrolments = createAsyncThunk(
    'enrolments/getEnrolments',
    async()=>{
        try {
          const response = await axios.get(`${url}/enrolments`)  
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)
const enrolmentsSlice = createSlice({
    name:'enrolments',
    initialState,
    reducers:{},
    extraReducers:{
        [getEnrolments.pending]:(state,action)=>{state.status = 'pending'},
        [getEnrolments.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getEnrolments.rejected]:(state,action)=>{state.status='rejected'}
}
})

export default enrolmentsSlice.reducer;