import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

const MailDelivery = props => {
const{email} = useParams()
    return <div className='container'>
        <p style={{padding:'30px'}}>A mail was sent to {email}. Please check your email to complete the process.</p>
        <a href='/login' className='btn' role='button'>Go to login</a>
    </div>;
};

MailDelivery.propTypes = {};

export { MailDelivery };