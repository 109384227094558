import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../Redux/Slices/AuthSlice';
import logo from '../logo.png'
import './Nav.css'
import { FaSearch } from 'react-icons/fa';


function NavBar() {
  const user = useSelector(state=>state.auth.user);
  const dispatch = useDispatch()
  return (
    <Navbar expand="lg" className="bg-body-tertiary fixed-top">
      <Container fluid>
        <Navbar.Brand href="/">
        <img src={logo} className='logo'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/contact-us" className="navbar-link">Contact</Nav.Link>
            {user?<Nav.Link className="navbar-link" href={`/workbook/web-client/${user?._id}`}>Workbook</Nav.Link>:<Nav.Link href={`/workbook/web-client`}>Workbook</Nav.Link>}
            <Nav.Link className="navbar-link" href={`/courses`}>Courses</Nav.Link>
            <Nav.Link className="navbar-link" href={`/event-spaces`}>Pricing</Nav.Link>
            {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#" disabled>
              Link
            </Nav.Link> */}
          </Nav>
          {
              window.location.pathname==='/courses'&&<Form className="d-flex" style={{marginRight:'50px'}}>
              <Form.Control
                type="search"
                placeholder="Search for courses"
                className="me-2"
                aria-label="Search"
                onChange={e=>localStorage.setItem('searched',JSON.stringify(e.target.value))}
              />
              {/* <input className="form-control me-2" type="search" onChange={e=>localStorage.setItem('searched',JSON.stringify(e.target.value))} placeholder="Search" aria-label="Search" /> */}
              {/* <FaSearch size={24}/> */}
            </Form>
            }
            <Nav style={{marginRight:'50px'}}>
        
        {user?.isAdmin&&
            <Nav.Link className="navbar-link" href={`/dashboard`}>Dashboard</Nav.Link>}
            {user?.isAdmin&&
            <Nav.Link className="navbar-link" href={`/setting`}>Setting</Nav.Link>}
          {user?.isStudent&&
            <Nav.Link className="navbar-link" href={`/${user?._id}/dashboard`}>My Dashboard</Nav.Link>}
            
            {user?
            <><Nav.Link className="navbar-link" href={`/${user?._id}/profile`}>Profile</Nav.Link><Nav.Link onClick={()=>dispatch(logOut())}>Logout</Nav.Link></>
            :<><Nav.Link className="navbar-link" href={`/login`}>Login</Nav.Link>
        <Nav.Link className="navbar-link" href={`/register`}>Register</Nav.Link></>}
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;