import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { loadUser, loginUser } from '../../../Redux/Slices/AuthSlice';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { url } from '../../../Redux/Api';

const Login = props => {
    const[email, setEmail] = useState('')
    const[password, setPassword] = useState('')
    const[loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const[newState, setNewState] = useState({status:'',data:''});

const login = async(e)=>{
    setLoading(true)
    dispatch(loginUser({email,password}))
    // await fetch(`${url}/login`,{
    //     mode:'cors',
    //     method:'post',
    //     headers:{
    //         'Content-Type':'application/json',
    //     },
    //     body:JSON.stringify({
    //         email,password
    //     })
    // }).then(res=>res.json())
    // .then(resp=>{
    //     if(resp.user){
    //         console.log('1',resp.user)
    //         console.log('2',resp.token)
    //         // localStorage.setItem('user',JSON.stringify(resp.user))
    //         // localStorage.setItem('token',JSON.stringify(resp.token));
    //         // dispatch(loadUser({user:resp.user,token:resp.token}));
    //         // window.location.reload()
    //         return navigate(`/${resp.user._id}/profile`)
    //     }else if(resp.err){
    //         setLoading(false)
    //         return setNewState({status:'failed',data:resp.err})
    //     }
    // }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
}
    return <div className='container' style={{marginBottom:'100px',marginTop:'100px'}}>
        <form>
            <p style={{textAlign:'center'}}>{newState.data.length>0?newState.data:'Login'}</p>
        <div className='inputs-wrapper'>
        <div className="mb-3 row">
    <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
    <div className="col-sm-10">
      <input className="form-control" onChange={(e) => setEmail(e.target.value)} id="email" 
      type='email'
      required
      />
    </div>
    </div>
<div className="mb-3 row">
    <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
    <div className="col-sm-10">
      <input className="form-control" onChange={(e) => setPassword(e.target.value)} id="password" 
      type='password'
      required
      />
    </div>
    </div>
  </div>
        {
            loading?
            <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>:<Button type='submit' onClick={login} className='contact-btn'>Login</Button>
        }
        </form>
        <Row>
        <Col style={{marginTop:'20px'}}>Don't have an account? <a style={{textDecoration:'none'}} href='/register'>Create Account</a></Col>
        <Col style={{marginTop:'20px'}}>Forgotten password? <a style={{textDecoration:'none'}} href='/password-change'>Reset Password</a></Col>
        </Row>
    </div>;
};

Login.propTypes = {};

export { Login };