import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import placeholder from '../../../assets/placeholder.png';
import { Button, ButtonGroup, Form, FormControl, ListGroup, ListGroupItem, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { url } from '../../../Redux/Api';
import './Profile.css'

const Profile = props => {
    const user = useSelector(state=>state.auth.user);
    const users = useSelector(state=>state.users.items);
    const courses = useSelector(state=>state.courses.items);
    const enrolments = useSelector(state=>state.enrolments.items);
    const lessons = useSelector(state=>state.lessons.items);
    const subscriptions = useSelector(state=>state.subscriptions.items);

    const[partTimeDuration, setPartTimeDuration] = useState(36);
    const[regularDuration, setRegularDuration] = useState(12);
    const[partTimeMeetingDays, setPartTimeMeetingDays] = useState('Saturdays');
    const[partTimeMeetingTime, setPartTimeMeetingTime] = useState('3pm-6pm')
    const[regularMeetingDays, setRegularMeetingDays] = useState('Mondays, Wednesdays, Fridays');
    const[regularMeetingTime, setRegularMeetingTime] = useState('4pm-6pm');
    const[description, setDescription] = useState('');
    const[cost, setCost] = useState(0);
    const[imagePreview, setImagePreview] = useState('');
    const[owner, setOwner] = useState('');
    const[venue, setVenue] = useState('Retiny Event Space');

    function handleChangeProfilePic(e){
      e.preventDefault()
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/change-profile-picture`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
src:profilePicPreview
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handleSubmit(e){
      e.preventDefault()
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/create-course`,{
                  mode:'cors',
                  method:'post',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                      title,regularDuration,partTimeDuration,venue,
partTimeMeeting:{partTimeMeetingDays,partTimeMeetingTime},
regularMeeting:{regularMeetingDays,regularMeetingTime},cost,
description,
src:imagePreview,owner
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
  const handleFile = async (a)=>{
    const data = a.target.files[0];
    const base64 = await Converter(data);
    setImagePreview(base64);
  }
  const Converter = (e)=>{
    return new Promise((resolve,reject)=>{
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = ()=>{
        resolve(reader.result)
      }
      reader.onerror = (error)=>{
        reject(error)
      }
    })
  }

  const handleProfilePic = async (a)=>{
    const data = a.target.files[0];
    const base64 = await ConvertProfilePic(data);
    setProfilePicPreview(base64);
    
  }
  const ConvertProfilePic = (e)=>{
    return new Promise((resolve,reject)=>{
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = ()=>{
        resolve(reader.result)
      }
      reader.onerror = (error)=>{
        reject(error)
      }
    })
  }

    const navigate = useNavigate();
    const[active, setActive] = useState('courses');
    const[course, setCourse] = useState(null);
    const[loading, setLoading] = useState(false);
    const[newState, setNewState] = useState({status:'',data:''});
    const[title, setTitle] = useState('');
    const[language,setLanguage] = useState('');
    const [show, setShow] = useState(false);
    const[passKey, setPassKey] = useState('');
    const[changer, setChnager] = useState(false);
    const[profilePicPreview,setProfilePicPreview] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleSelectCourse = (a)=>{
      if(courses.length>0){
        let b = courses.filter(filt=>filt._id?.toString()===a._id?.toString())
        setCourse(b[0])
      }else{
       return window.location.reload()
      }
      
    }
    
    function handleStartLesson(e){
      setLoading(true)
     async function fetchData(){
        await fetch(`${url}/start-lesson`,{
            mode:'cors',
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({
              enrolmentId:e._id
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setLoading(false)
                setNewState({status:'success',data:resp.resp})
                return navigate(`/lessons/classroom/${resp.resp.title}/${resp.resp._id}`)
              }else if(resp.err){
                setLoading(false)
                return setNewState({status:'failed',data:resp.err})
            }else{
                setLoading(false)
                return setNewState({status:'failed',data:'Something went wrong.'})
            }
        }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
    }
    fetchData();
  }

  function handleCreateClass(){
    setLoading(true)
   if(course){
    async function fetchData(){
      await fetch(`${url}/create-lesson`,{
          mode:'cors',
          method:'post',
          headers:{
              'Content-Type':'application/json',
              'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
          },body:JSON.stringify({
            courseId:course._id,title,language,passKey
          })
      }).then(res=>res.json())
      .then(resp=>{
          if(resp.resp){
              setLoading(false)
              setNewState({status:'success',data:resp.resp})
              navigate(`/lessons/classroom/${resp.resp.title}/${resp.resp._id}`)
            }else if(resp.err){
              setLoading(false)
              return setNewState({status:'failed',data:resp.err})
          }else{
              setLoading(false)
              return setNewState({status:'failed',data:'Something went wrong.'})
          }
      }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
  }
  fetchData();
   }
}

function handlePaymentConfirmation(e){
  setLoading(true)

  async function fetchData(){
          await fetch(`${url}/confirm-payment`,{
              mode:'cors',
              method:'put',
              headers:{
                  'Content-Type':'application/json',
                  'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
              },body:JSON.stringify({
                  enrolmentId:e._id
              })
          }).then(res=>res.json())
          .then(resp=>{
              if(resp.resp){
                  setLoading(false)
                  setNewState({status:'success',data:resp.resp})
                  return window.location.reload()
              }else if(resp.err){
                  setLoading(false)
                  return setNewState({status:'failed',data:resp.err})
              }else{
                  setLoading(false)
                  return setNewState({status:'failed',data:'Something went wrong.'})
              }
          }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
      }
      fetchData();
}

    return <Container className='profile-container'>
        <input type='file' onChange={handleProfilePic}  className='img-overlay' />
                {user?.image?.toString()!=='image'?
                 <Row>
                 <Col xs={6} md={4}>
                   <Image src={user?.image} width={171} height={180} thumbnail  />
                 </Col>
               </Row>:
      <Row>
      <Col xs={6} md={4}>
        <Image src={profilePicPreview.length>0?profilePicPreview:placeholder} width={171} height={180} thumbnail />
      </Col>
      {profilePicPreview.length>0&&<Button onClick={handleChangeProfilePic}>Continue</Button>}
    </Row>
    }
    <Row>
    {subscriptions?.filter(filt=>filt.subscriber.toString()===user?._id?.toString())?.length>0&&<Link to='/event-spaces'>Explore event spaces</Link> }
    </Row>
    <Row>
       {
        subscriptions?.filter(filt=>filt.subscriber.toString()===user?._id?.toString())?.length>0?
        null :<Link to='/event-spaces'>Rent an event space to host your events</Link> 
        
       }
       {!user?.eligible&&<Link to='/teaching-request'>Post your course</Link>}
       <div style={{marginBottom:'50px'}}/>
       <h2>Private</h2>
       <Tabs
        defaultActiveKey={active==='completed'?'completed':active==='running'?'running':'courses'}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <h2>{newState.data}</h2>
          <Tab eventKey="enrolments" title="My Enrolments"  onClick={()=>setActive('enrolments')}>
              {enrolments?.filter(filt=>filt.student===user?._id).map((item,index)=>{
                  return(
       <Row key={index}>
         <ListGroup >
          <ListGroupItem>{item?.title}</ListGroupItem>
        </ListGroup>
       </Row>
                  )
              })}
        </Tab>
        <Tab eventKey="lessons" title="lessons"  onClick={()=>setActive('lessons')}>
              {lessons?.filter(filt=>filt?.participants?.toString()?.includes(user?._id?.toString()))?.map((item,index)=>{
                  return(
       <Row key={index}>
         <ListGroup >
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Topic</span><span style={{color:'gray',marginTop:'20px'}}>{item?.title}</span></ListGroupItem>
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Tutor</span><span style={{color:'gray',marginTop:'20px'}}>{`${users?.filter(fil=>fil?._id?.toString()===item?.tutor?.toString())[0]?.firstName} ${users?.filter(fil=>fil?._id?.toString()===item?.tutor?.toString())[0]?.lastName}`}</span></ListGroupItem>
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Works</span><span style={{color:'gray',marginTop:'20px'}}><Link to={`/works/${item?._id}`}>Works</Link></span></ListGroupItem>
          {item?.running&&
           <ListGroupItem><Button onClick={()=>navigate(`/lessons/classroom/${item?.title?.toLowerCase()}/${item?._id?.toString()}`)}>Join Class</Button></ListGroupItem> 
          }
        </ListGroup>
       </Row>
                  )
              })}
        </Tab>
        <Tab eventKey="running" title="Running Courses"  onClick={()=>setActive('running')}>
        {!show?enrolments?.filter(filt=>{
          if(user?.isAdmin)return true;if(filt.student.toString()===user?._id?.toString()&&!filt.done)return true;
        }).map((item,index)=>{
                  return(
        <ListGroup key={index} onMouseEnter={()=>handleSelectCourse(item)}>
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Category</span><span style={{color:'gray',marginTop:'20px'}}>{item?.category==='part-time'?'Part-time program':'Regular program'}</span></ListGroupItem>
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Course Tite</span><span style={{color:'gray',marginTop:'20px'}}>{item?.title}</span></ListGroupItem>
       <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>On scholarship</span><span style={{color:'gray',marginTop:'20px'}}> {item?.scholarship?'Yes':'No'}</span></ListGroupItem>
        <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Lesson Days</span><span style={{color:'gray',marginTop:'20px'}}>{item?.meeting}</span></ListGroupItem>
        <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Lesson Venue</span><span style={{color:'gray',marginTop:'20px'}}>{item?.venue}</span></ListGroupItem>
        <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Duration</span><span style={{color:'gray',marginTop:'20px'}}>{item?.duration} Weeks</span></ListGroupItem>
        {!user?.isAdmin&&<ListGroupItem className='list-group-item'>{item?.paid?
          <>{
            lessons?.filter(filt=>filt?.participants?.toString()?.includes(user?._id?.toString())).length>0?null:
            loading?<Spinner/>:<Button onClick={()=>handleStartLesson(item)}>Start Lesson</Button>
            
            }</>
          :`Pay $${item.cost} to join class`}<span style={{color:'gray',marginTop:'20px'}}>${item?.cost}</span></ListGroupItem>}
        </ListGroup>
                  )
              })
              :
              <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-center">{course?.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {newState.data.length>0?newState.data:<p>You're about starting a lesson for {course?.title}</p>}
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control as="textarea" onChange={e=>setTitle(e.target.value)} placeholder="Title for today's Lesson" rows={3} autoFocus />
                    <Form.Control as="textarea" onChange={e=>setLanguage(e.target.value)} placeholder="Language" rows={3} autoFocus required='false' />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                {
                  loading?<Spinner/>:
                  <ButtonGroup>
                  <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleCreateClass}>
                  Continue
                </Button>
                  </ButtonGroup>
                }
              </Modal.Footer>
            </Modal>
              }
        </Tab>
        
        <Tab eventKey="completed" title="Completed Courses" onClick={()=>setActive('completed')}>
        {enrolments?.filter(filt=>filt.student===user?._id&&filt.done).map((item,index)=>{
                  return(
        <ListGroup key={index}>
          <ListGroupItem>{item?.title}</ListGroupItem>
        </ListGroup>
                  )
              })}
        </Tab>
        <Tab eventKey="user" title="User Details" onClick={()=>setActive('user')}>
        <ListGroup>
        <ListGroupItem>{user?.userName}</ListGroupItem>
        <ListGroupItem>{`${user?.firstName} ${user?.middleName} ${user?.lastName}`}</ListGroupItem>
              <ListGroupItem>{user?.phone1}</ListGroupItem>
              <ListGroupItem>{user?.email}</ListGroupItem>
              <ListGroupItem>{user?.eligible?'Verified Account':'Not verified'}</ListGroupItem>
          </ListGroup>
        </Tab>
      </Tabs>
    </Row>
    <Row>
       <div style={{marginBottom:'50px'}}/>
       <h2>Public</h2>
       <Tabs
        defaultActiveKey={active==='completed-public'?'completed-public':active==='running-public'?'running-public':'public-enrolments'}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <h2>{newState.data}</h2>
          <Tab eventKey="public-enrolments" title="Enrolments"  onClick={()=>setActive('public-enrolments')}>
              {enrolments?.filter(filt=>filt?.tutor?.toString()===user?._id?.toString()).map((item,index)=>{
                  return(
       <Row key={index}>
         <ListGroup >
          <ListGroupItem>{item?.title}</ListGroupItem>
        </ListGroup>
       </Row>
                  )
              })}
        </Tab>
        <Tab eventKey="public-lessons" title="lessons"  onClick={()=>setActive('public-lessons')}>
              {lessons?.filter(filt=>filt?.tutor?.toString()===user?._id?.toString()||filt?.participants?.toString()?.includes(user?._id?.toString()))?.map((item,index)=>{
              // {lessons?.filter(filt=>filt)?.map((item,index)=>{
                  return(
       <Row key={index}>
         <ListGroup >
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Topic</span><span style={{color:'gray',marginTop:'20px'}}>{item?.title}</span></ListGroupItem>
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Tutor</span><span style={{color:'gray',marginTop:'20px'}}>{`${users?.filter(fil=>fil?._id?.toString()===item?.tutor?.toString())[0]?.firstName} ${users?.filter(fil=>fil?._id?.toString()===item?.tutor?.toString())[0]?.lastName}`}</span></ListGroupItem>
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Works</span><span style={{color:'gray',marginTop:'20px'}}><Link to={`/works/${item?._id}`}>Works</Link></span></ListGroupItem>
          {item?.running&&
           <ListGroupItem><Button onClick={()=>navigate(`/lessons/classroom/${item?.title?.toLowerCase()}/${item?._id?.toString()}`)}>Join Class</Button></ListGroupItem> 
          }
        </ListGroup>
       </Row>
                  )
              })}
        </Tab>
        <Tab eventKey="pubblic-running" title="Running Courses"  onClick={()=>setActive('pubblic-running')}>
        {!show?courses?.filter(filt=>filt?.tutors?.includes(user?._id?.toString())).map((item,index)=>{
                  return(
        <ListGroup key={index} onMouseEnter={()=>handleSelectCourse(item)}>
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Category</span><span style={{color:'gray',marginTop:'20px'}}>{item?.category==='part-time'?'Part-time program':'Regular program'}</span></ListGroupItem>
          <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Course Tite</span><span style={{color:'gray',marginTop:'20px'}}>{item?.title}</span></ListGroupItem>
       <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>On scholarship</span><span style={{color:'gray',marginTop:'20px'}}> {item?.scholarship?'Yes':'No'}</span></ListGroupItem>
        <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Lesson Days</span><span style={{color:'gray',marginTop:'20px'}}>{item?.meeting}</span></ListGroupItem>
        <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Lesson Venue</span><span style={{color:'gray',marginTop:'20px'}}>{item?.venue}</span></ListGroupItem>
        <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Duration</span><span style={{color:'gray',marginTop:'20px'}}>{item?.duration} Weeks</span></ListGroupItem>
        <select onChange={e=>setPassKey(e.target.value)} >
          <option>Select an event space</option>
          {
            subscriptions?.filter(filt=>filt.subscriber.toString()===user?._id?.toString())
            ?.map((itm,ind)=><option key={ind} value={itm.passKey}>{itm.passKey}</option>)
          }
        </select>
        <Button onClick={()=>{if(passKey.length>0){return setShow(true)};handleSelectCourse(item)}}>Create New Class</Button>
        </ListGroup>
                  )
              })
              :
              <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-center">{course?.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {newState.data.length>0?newState.data:<p>You're about starting a lesson for {course?.title}</p>}
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control as="textarea" onChange={e=>setTitle(e.target.value)} placeholder="Title for today's Lesson" rows={3} autoFocus />
                    <Form.Control as="textarea" onChange={e=>setLanguage(e.target.value)} placeholder="Language" rows={3} autoFocus required='false' />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                {
                  loading?<Spinner/>:
                  <ButtonGroup>
                  <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleCreateClass}>
                  Continue
                </Button>
                  </ButtonGroup>
                }
              </Modal.Footer>
            </Modal>
              }
        </Tab>
        
        <Tab eventKey="public-completed" title="Completed Courses" onClick={()=>setActive('public-completed')}>
        {enrolments?.filter(filt=>filt.student!==user?._id&&filt.done).map((item,index)=>{
                  return(
        <ListGroup key={index}>
          <ListGroupItem>{item?.title}</ListGroupItem>
        </ListGroup>
                  )
              })}
        </Tab>
        <Tab eventKey="courses" title="Course Form" onClick={()=>setActive('courses')}>
        <div style={{margin:'40px auto'}}>
                <h3>Create New Course</h3>
        </div>
        {newState.data.length>0&&<p>{newState.data}</p>}
        <Form onSubmit={handleSubmit} autoComplete='true'>
        <Col>
        <Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Title</span><FormControl required
        // placeholder='Course title'
        onChange={e=>setTitle(e.target.value)}
        />
        </Row>

<Row xl={6} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Course Description</span><FormControl required
        // placeholder='Course detailed description.'
        onChange={e=>setDescription(e.target.value)}
        />
</Row>
<Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Cost</span><FormControl required
        // placeholder='Cost.'
        type='number'
        min={0}
        onChange={e=>setCost(e.target.value)}
        />
        </Row>
        <Row xl={6} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Venue</span><FormControl required
        // placeholder='Lessons Venue'
        value={venue}
        disabled
        onChange={e=>setVenue(e.target.value)}
        />
</Row>
<Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Course Thumbnail</span><FormControl required
        type='file'
        onChange={handleFile}
        />
        {
          imagePreview.length>0&&
          <Row>
          <img src={imagePreview} alt='' height={'auto'} width={'100%'} />
        </Row>
        }
        </Row>
        <Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Company Name</span><FormControl required
        // placeholder='Course thumbnail.'
        onChange={e=>setOwner(e.target.value)}
        />
        </Row>
<Row>
<Col md={6} style={{padding:'20px'}}>
<div style={{margin:'40px auto',borderBottom:'2px solid var(--primary-1)'}}>
                <h3>Regular Section</h3>
        </div>
        <Row xl={12} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Duration</span><FormControl type='number' min={0} required
                // placeholder='The duration of the training.'
                onChange={e=>setRegularDuration(e.target.value)}
                value={regularDuration}
                />
        </Row>
<Row xl={12} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Meeting Days</span><FormControl required
        // placeholder='Regular meeting days'
        value={regularMeetingDays}
        onChange={e=>setRegularMeetingDays(e.target.value)}
        />
</Row>
<Row xl={12} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Meeting Time</span><FormControl required
        // placeholder='Regular meeting time'
        value={regularMeetingTime}
        onChange={e=>setRegularMeetingTime(e.target.value)}
        />
</Row>
</Col>
<Col md={6} style={{padding:'20px'}}>
<div style={{margin:'40px auto',borderBottom:'2px solid var(--primary-1)'}}>
                <h3>Part-Time Section</h3>
        </div>
        
<Row xl={12} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Duration</span><FormControl type='number' min={0} required
        // placeholder='Part Time Duration'
        
        value={partTimeDuration}
        onChange={e=>setPartTimeDuration(e.target.value)}
        />
</Row>
        <Row xl={12} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Meeting Days</span><FormControl required
        // placeholder='Part-time meeting days'
        
        value={partTimeMeetingDays}
        onChange={e=>setPartTimeMeetingDays(e.target.value)}
        />
        </Row>
        <Row xl={12} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Meeting Time</span><FormControl required
        // placeholder='Part-time meeting time'
        
        value={partTimeMeetingTime}
        onChange={e=>setPartTimeMeetingTime(e.target.value)}
        />
        </Row>
</Col>
</Row>
        </Col>
        {loading?<Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>:<Button type='submit'>Create</Button>}
        </Form>
      </Tab>
      </Tabs>
    </Row>
        </Container>;
};

Profile.propTypes = {};

export { Profile };