import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { url } from '../../../Redux/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { loadUser } from '../../../Redux/Slices/AuthSlice';
import { useDispatch } from 'react-redux';
import { Form, FormControl, Row } from 'react-bootstrap';

const ResetPassword = props => {
    const[newState, setNewState] = useState({status:'',data:''});
    const[password, setPassword] = useState('')
    const[confirmPassword, setConfirmPassword] = useState('')
    const[loading, setLoading] = useState(false);
    const{email,token} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()

const passwordReset = async(e)=>{
    setLoading(true)
    await fetch(`${url}/password-reset`,{
        mode:'cors',
        method:'post',
        headers:{
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            password,email,token
        })
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            return navigate(`/login`)
        }else if(resp.err){
            setLoading(false)
            return setNewState({status:'failed',data:resp.err})
        }
    }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
}
    return <div className='container'>
        <Form>
            <p style={{textAlign:'center'}}>{newState.data.length>0?newState.data:'Reset Password'}</p>
        <div className='inputs-wrapper'>
        <Row className="mb-3 row">
    <span>New Password</span>
    <FormControl  
      onChange={(e) => setPassword(e.target.value)}
      type='password'
      required
      />
    </Row>
    <Row className="mb-3 row">
    <span>Confirm New Password</span>
    <FormControl  
      onChange={(e) => setConfirmPassword(e.target.value)}
      type='password'
      required
      />
    </Row>
  </div>
        {
            loading?
            <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>:password===confirmPassword&&password.length>0&&<a type='submit' onClick={passwordReset} role='button' className='btn'>Continue</a>
        }
        </Form>
    </div>;
};

ResetPassword.propTypes = {};

export { ResetPassword };