import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { setAuthHeader, url } from "../Api"

const initialState = {
    items:[],
    status:''
}

export const getContacts = createAsyncThunk(
    'contacts/getContacts',
    async()=>{
        try {
            const response = await axios.get(`${url}/contacts`,setAuthHeader())
            if(response){
                return response.data.resp
            }else{
                return 
            }
        } catch (error) {
            return
        }
    }
)
const contactsSlice = createSlice({
    name:'contacts',
    initialState,
    reducers:{
        loadcontacts(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getContacts.pending]:(state,action)=>{state.status='pending'},
        [getContacts.fulfilled]:(state,action)=>{state.items=action.payload;state.status = 'fulfilled'},
        [getContacts.rejected]:(state,action)=>{state.status = 'rejected'}
    }
})

export const{loadcontacts} = contactsSlice.actions;
export default contactsSlice.reducer;