import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import NavBar from './Parts/Nav';
import { Courses } from './Components/Pages/Courses';
import { Course } from './Components/Pages/Course';
import Confirmation from './Components/Pages/User/Confirmation';
import { useSelector } from 'react-redux';
import { PasswordChange } from './Components/Pages/User/PasswordChange';
import { Login } from './Components/Pages/User/Login';
import { Profile } from './Components/Pages/User/Profile';
import { UserDashboard } from './Components/Pages/User/UserDashboard';
import { Register } from './Components/Pages/User/Register';
import Payer from './Components/Pages/googlePAY';
import { PaymentSuccess } from './Components/Pages/PaymentSuccess';
import { PaymentGateWay } from './Components/Pages/PaymentGateWay';
import { MailDelivery } from './Components/Pages/User/MailDelivery';
import { Contact } from './Components/Pages/User/Contact';
import { Dashboard } from './Components/Pages/User/Dashboard';
import { ChangePassword } from './Components/Pages/User/ChangePassword';
import { ResetPassword } from './Components/Pages/User/PasswordReset';
import { PasswordSent } from './Components/Pages/User/PasswordSent';
import { Blog } from './Components/Pages/BLog/Blog';
import Workbook from './Components/Pages/BLog/Workbook';
import Editor from './Components/Pages/BLog/Editor';
import { Classroom } from './Components/Pages/Venue/Classroom';
import { Codes } from './Components/Pages/Venue/Codes';
import Coders from './Components/Pages/BLog/Coders';
import { Spaces } from './Components/Pages/Spaces';
import { SpaceDetail } from './Components/Pages/SpaceDetail';
import { WorkDetail } from './Components/Pages/User/WorkDetail';
import { Works } from './Components/Pages/User/Works';
import { TeachingRequest } from './Components/Pages/User/TeachingRequest';
import { Home } from './Home';
import Footer from './Parts/Footer';
import Faq from './Pages/Faq';
import Learn from './Pages/Learn';
import AppSetting from './AppSetup';
import { VideoCall } from './Components/Pages/Venue/VideoCall';

//Include speech to text in the classroom.

function App() {
  const user = useSelector(state=>state.auth.user)
  return (
  <BrowserRouter>
    <NavBar/>
  <Routes>
  <Route path='/' element={<Home/>} />
    <Route path='/courses' element={<Courses/>} />
    <Route path='/course/:courseId' element={<Course/>} />
    <Route path={`/:userId/profile`} element={!user?<Navigate replace to = {`/login`}/>:<Profile />} />
    <Route path={`/setting`} element={!user?<Navigate replace to = {`/login`}/>:<AppSetting />} />
    <Route path='/:userId/dashboard' element={user?<Navigate replace to = {`/login`}/>:<UserDashboard />} />
    <Route path='/dashboard' element={!user?<Navigate replace to = {`/login`}/>:<Dashboard />} />
    <Route path = {`/:token/:email/authentication`} element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Confirmation />}/>
    <Route path = {`/:email/:token/password-reset`} element={<ResetPassword />} />
    <Route path='/password-change' element={<ChangePassword />} />
    <Route path='/login' element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Login />} />
    <Route path='/:email/mail-delivery' element={<MailDelivery/>} />
    <Route path='/:email/password-sent' element={<PasswordSent/>} />
    <Route path='/register' element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Register />} />
    <Route path='/payment-success/:category/:courseId' element={!user?<Navigate replace to = {`/login`}/>:<PaymentSuccess />} />
    <Route path='/payment-gateway/:category/:courseId' element={!user?<Navigate replace to = {`/login`}/>:<PaymentGateWay />} />
    <Route path='/blog/:blogCourse/:blogId' element={<Blog />} />
    <Route path='/blog/:blogCourse' element={<Blog />} />
    <Route path='/workbook/:type' element={<Workbook/>} />
    <Route path='/workbook/:type/:userId' element={!user?<Navigate replace to = {`/login`}/>:<Workbook />} />
    {/* <Route path='/codes' element={<Codes />} /> */}
    {/* <Route path='/codes/coders' element={<Coders />} /> */}
    <Route path='/event-spaces' element={<Spaces />} />
    <Route path='/event-space/:spaceId' element={<SpaceDetail />} />
    <Route path='/works/:lessonId' element={!user?<Navigate replace to = {`/login`}/>:<Works />} />
    <Route path='/work/:workId' element={!user?<Navigate replace to = {`/login`}/>:<WorkDetail />} />
    <Route path='/contact-us' element={<Contact />} />
    <Route path='/teaching-request' element={<TeachingRequest />} />
    <Route path='/faq' element={<Faq />} />
    <Route path='/learn' element={<Learn />} />
    <Route path='/video-call' element={<VideoCall />} />
    <Route path='/lessons/classroom/:title/:lessonId' element={!user?<Navigate replace to = {`/login`}/>:<Classroom />} />

  </Routes>
  <Footer/>
  </BrowserRouter>
  );
}

export default App;