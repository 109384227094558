import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:localStorage.getItem('courses')?JSON.parse(localStorage.getItem('courses')):[],
    status:''
}
export const getCourses = createAsyncThunk(
    'courses/getCourses',
    async()=>{
        try {
          const response = await axios.get(`${url}/courses`)  
               // declare the function 
const shuffle = (array) => { 
    for (let i = array.length - 1; i > 0; i--) { 
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
  }; 
const shuffledArray = shuffle(response.data.resp); 

          localStorage.setItem('courses',JSON.stringify(shuffledArray))

          return shuffledArray
        } catch (error) {
            return 
        }
    }
)

const coursesSlice = createSlice({
    name:'courses',
    initialState,
    reducers:{
        loadCourses(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getCourses.pending]:(state,action)=>{state.status = 'pending'},
        [getCourses.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getCourses.rejected]:(state,action)=>{state.status='rejected'}
}
})

export const{loadCourses} = coursesSlice.actions;
export default coursesSlice.reducer;