import React, {useEffect, useState} from 'react';
import GooglePayButton from '@google-pay/button-react'
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Card, CardText, Container, ModalBody, Spinner } from 'react-bootstrap';
import { url } from '../../Redux/Api';
import './Courses.css'

const PaymentGateWay = props => {
const{category,courseId} = useParams();
const course = useSelector(stata=>stata.course.course);
const[newState, setNewState] = useState({status:'',data:''});
const[loading, setLoading] = useState(false);

const dispatch = useDispatch();
const navigate = useNavigate();

useEffect(()=>{console.log('coure',course)},[course])
const handleReg= (e)=>{
  setLoading(true)
  async function fetchData(){
      await fetch(`${url}/course-registration/${courseId}/${category}`,{
          mode:'cors',
          method:'post',
          headers:{
              'Content-Type':'application/json',
              'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
          }
      }).then(res=>res.json())
      .then(resp=>{
          if(resp.resp){
            setLoading(false)
               setNewState({status:'success',data:resp.resp})
               return navigate(`/payment-success/${category}/${courseId}`)
          }else if(resp.err){
            setLoading(false)
              return setNewState({status:'failed',data:resp.err})
          }else{
            setLoading(false)
              return setNewState({status:'failed',data:'Something went wrong.'})
          }
      }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
  }
  fetchData();
}

return <Container className='payment-gateway'>
        <Card className='payment-card'>

<ModalBody>
  <CardText style={{textAlign:'center',color:'var(--danger-1)',padding:'10px'}}>{newState.data}</CardText>
<p style={{textAlign:'center',fontWeight:'bold'}}>Pay the sum of ${course?.cost} for {course.title} {category} program.</p>
<p style={{textAlign:'center'}}>Click "Cash Payment" if you want to make in-person payment.</p>
      </ModalBody>
      {loading?<Spinner/>:<ButtonGroup className="btn-group">
        <Button className='payment-gateway-btn-cancel' style={{borderRadius:'4px'}} onClick={()=>navigate(-1)}>Cancel</Button>
        <Button className='payment-gateway-btn-proceed' style={{borderRadius:'4px'}} onClick={handleReg}>Cash Payment</Button>
        <GooglePayButton
  environment="TEST"
  paymentRequest={{
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['MASTERCARD', 'VISA'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'example',
            gatewayMerchantId: 'exampleGatewayMerchantId',
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: '12345678901234567890',
      merchantName: 'Retiny Technology Ltd',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: course?.price,
      currencyCode: 'USD',
      countryCode: 'US',
    },
  }}
  onLoadPaymentData={paymentRequest => {
    console.log('load payment data', paymentRequest);
    handleReg()
  }}
/>
      </ButtonGroup>}
</Card>
    </Container>;
};

PaymentGateWay.propTypes = {};

export { PaymentGateWay };