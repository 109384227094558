import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAuthHeader, url } from "../Api";

const initialState = {
    items:localStorage.getItem('subscriptions')?JSON.parse(localStorage.getItem('subscriptions')):[],
    status:''
}
export const getSubscriptions = createAsyncThunk(
    'subscriptions/getSubscriptions',
    async()=>{
        try {
          const response = await axios.get(`${url}/subscriptions`,setAuthHeader());
          localStorage.setItem('subscriptions',JSON.stringify(response.data.resp));
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)

const subscriptionsSlice = createSlice({
    name:'subscriptions',
    initialState,
    reducers:{
        loadSubscriptions(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getSubscriptions.pending]:(state,action)=>{state.status = 'pending'},
        [getSubscriptions.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getSubscriptions.rejected]:(state,action)=>{state.status='rejected'}
}
})

export const{loadSubscriptions} = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;