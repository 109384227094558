import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Form, FormControl, FormText, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../../Redux/Api';

const ValuePiece = Date | null;

const Value = ValuePiece | [ValuePiece, ValuePiece];

const Works = props => {
  const user = useSelector(state=>state.auth.user);
    const works = useSelector(state=>state.works.items);
    const lessons = useSelector(state=>state.lessons.items);
    const[lesson, setLesson]= useState({});
    const[workType, setWorkType] = useState('');
    const[workTitle, setWorkTitle] = useState('');
    const[description, setDescription] = useState('');
    const[language, setLanguage] = useState('');
    const[code, setCode] = useState('');
    const[loading, setLoading] = useState(false);
    const {lessonId} = useParams();
    const[newState, setNewState] = useState({status:'',data:''});
    const[submissionDate, setSubmissionDate] = useState(new Date());
    const navigate = useNavigate();

    const handleCreateWork = ()=>{
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/create-work`,{
                  mode:'cors',
                  method:'post',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    type:workType,
                    title:workTitle,
                    submissionDate,
                    description,
                    language,
                    code,
                    lessonId
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
    }
    useEffect(()=>{
      let a = lessons.filter(filt=>filt.tutor.toString()===user._id.toString())
      setLesson(a[0]);
    },[lessonId])

    return(<Container>
{lesson&&<Row xl={12} md={12}>
{newState.data.length>0&&<FormText>{newState.data}</FormText>}
  
<FormControl onChange={e=>setWorkTitle(e.target.value)} placeholder='Title'/>
    <Form.Select aria-label="Default select example" onChange={e=>setWorkType(e.target.value)} size='sm'>
      <option>Select a work type</option>
      <option value='assignment'>Assignment</option>
      <option value='classwork'>Classwork</option>
      <option value='project'>Project</option>
    </Form.Select>
    <DatePicker onChange={setSubmissionDate} value={submissionDate} />
    <FormControl onChange={e=>setDescription(e.target.value)} placeholder='Description'/>
    <FormControl onChange={e=>setLanguage(e.target.value)} placeholder='Language'/>
    <FormControl onChange={e=>setCode(e.target.value)} placeholder='Code'/>
      {loading?<Spinner />:<Button onClick={handleCreateWork}>Create</Button>}
  
</Row>}
  {works?.filter(filt=>filt?.lessonId?.toString()===lessonId?.toString()&&filt?.type==='assignment')?.length>0&&<Row xl={12} md={12}>
      <h4 className="title">Assignments</h4>
      {
         works?.length>0&&works?.filter(filt=>filt?.lessonId?.toString()===lessonId?.toString()&&filt?.type==='assignment')?.map((item,index)=>{
          return(
      <Col xl={4} md={12}  key={index}>
      <Card key={index}>
      <CardBody className="card-body" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <CardTitle className="card-title">{item?.title}</CardTitle>
        <CardText className="card-text-spaces">{item?.description}</CardText>
        <Button onClick={()=>navigate(`/work/${item?._id}`)} style={{display:'flex',alignSelf:'center'}} className='btn'>Get started</Button>
      </CardBody>
      </Card>
      </Col>
      )
      
      })
      }
      </Row>}
      {works?.filter(filt=>filt?.lessonId?.toString()===lessonId?.toString()&&filt?.type==='classwork')?.length>0&&<Row xl={12} md={12}>
      <h4 className="title">Classworks</h4>
      {
         works?.filter(filt=>filt?.lessonId?.toString()===lessonId?.toString()&&filt?.type==='classwork')?.map((item,index)=>{
          return(
      <Col xl={4} md={12}  key={index}>
      <Card key={index}>
      <CardBody className="card-body" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <CardTitle className="card-title">{item?.title}</CardTitle>
        <CardText className="card-text-spaces">{item.description}</CardText>
        <Button onClick={()=>navigate(`/work/${item?._id}`)} style={{display:'flex',alignSelf:'center'}} className='btn'>Get started</Button>
      </CardBody>
      </Card>
      </Col>
      )
      
      })
      }
      </Row>}
      {works?.filter(filt=>filt?.lessonId?.toString()===lessonId?.toString()&&filt?.type==='project')?.length>0&&<Row xl={12} md={12}>
      <h4 className="title">Projects</h4>
      {
         works?.filter(filt=>filt?.lessonId?.toString()===lessonId?.toString()&&filt?.type==='project')?.map((item,index)=>{
          return(
      <Col xl={4} md={12}  key={index}>
      <Card key={index}>
      <CardBody className="card-body" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <CardTitle className="card-title">{item?.title}</CardTitle>
        <CardText className="card-text-spaces">{item.description}</CardText>
        <Button onClick={()=>navigate(`/work/${item?._id}`)} style={{display:'flex',alignSelf:'center'}} className='btn'>Get started</Button>
      </CardBody>
      </Card>
      </Col>
      )
      
      })
      }
      </Row>}
      </Container>)
};

Works.propTypes = {};

export { Works };