import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:localStorage.getItem('spaces')?JSON.parse(localStorage.getItem('spaces')):[],
    status:''
}
export const getSpaces = createAsyncThunk(
    'spaces/getSpaces',
    async()=>{
        try {
          const response = await axios.get(`${url}/spaces`);
          localStorage.setItem('spaces',JSON.stringify(response.data.resp));
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)

const spacesSlice = createSlice({
    name:'spaces',
    initialState,
    reducers:{
        loadSpaces(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getSpaces.pending]:(state,action)=>{state.status = 'pending'},
        [getSpaces.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getSpaces.rejected]:(state,action)=>{state.status='rejected'}
}
})

export const{loadSpaces} = spacesSlice.actions;
export default spacesSlice.reducer;