import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAuthHeader, url } from "../Api";

const initialState = {
    items:localStorage.getItem('lessons')?JSON.parse(localStorage.getItem('lessons')):[],
    status:''
}
export const getLessons = createAsyncThunk(
    'lessons/getLessons',
    async()=>{
        try {
          const response = await axios.get(`${url}/lessons`,setAuthHeader())  
               // declare the function 
const shuffle = (array) => { 
    for (let i = array.length - 1; i > 0; i--) { 
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
  }; 
const shuffledArray = shuffle(response.data.resp); 

          localStorage.setItem('lessons',JSON.stringify(shuffledArray))

          return shuffledArray
        } catch (error) {
            return 
        }
    }
)

const lessonsSlice = createSlice({
    name:'lessons',
    initialState,
    reducers:{
        loadLessons(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getLessons.pending]:(state,action)=>{state.status = 'pending'},
        [getLessons.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getLessons.rejected]:(state,action)=>{state.status='rejected'}
}
})

export const{loadLessons} = lessonsSlice.actions;
export default lessonsSlice.reducer;