import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:localStorage.getItem('blogs')?JSON.parse(localStorage.getItem('blogs')):[],
    status:''
}
export const getBlogs = createAsyncThunk(
    'blogs/getBlogs',
    async()=>{
        try {
          const response = await axios.get(`${url}/blogs`)  
          localStorage.setItem('blogs',JSON.stringify(response.data.resp))
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)

const blogsSlice = createSlice({
    name:'blogs',
    initialState,
    reducers:{
        loadBlogs(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getBlogs.pending]:(state,action)=>{state.status = 'pending'},
        [getBlogs.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getBlogs.rejected]:(state,action)=>{state.status='rejected'}
}
})

export const{loadBlogs} = blogsSlice.actions;
export default blogsSlice.reducer;