import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { url } from "../Api"

const initialState = {
    items:[],
    status:''
}

export const getVisitor = createAsyncThunk(
    'visitor/getVisitor',
    async()=>{
        try {
            const response = await axios.get(`${url}/visitor`)
            if(response){
                return response.data.resp
            }else{
                return 
            }
        } catch (error) {
            return
        }
    }
)
const visitorSlice = createSlice({
    name:'visitor',
    initialState,
    reducers:{
        loadVisitor(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getVisitor.pending]:(state,action)=>{state.status='pending'},
        [getVisitor.fulfilled]:(state,action)=>{state.items=action.payload;state.status = 'fulfilled'},
        [getVisitor.rejected]:(state,action)=>{state.status = 'rejected'}
    }
})

export const{loadVisitor} = visitorSlice.actions;
export default visitorSlice.reducer;