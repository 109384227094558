import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAuthHeader, url } from "../Api";

const initialState = {
    items:localStorage.getItem('submissions')?JSON.parse(localStorage.getItem('submissions')):[],
    status:''
}
export const getSubmissions = createAsyncThunk(
    'submissions/getSubmissions',
    async()=>{
        try {
          const response = await axios.get(`${url}/submissions`,setAuthHeader());
          localStorage.setItem('submissions',JSON.stringify(response.data.resp));
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)

const submissionsSlice = createSlice({
    name:'submissions',
    initialState,
    reducers:{
        loadSubmissions(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getSubmissions.pending]:(state,action)=>{state.status = 'pending'},
        [getSubmissions.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getSubmissions.rejected]:(state,action)=>{state.status='rejected'}
}
})

export const{loadSubmissions} = submissionsSlice.actions;
export default submissionsSlice.reducer;