import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { setAuthHeader, url } from "../Api"

const initialState = {
    items:[],
    status:''
}

export const getTeachings = createAsyncThunk(
    'teachings/getTeachings',
    async()=>{
        try {
            const response = await axios.get(`${url}/teachings`,setAuthHeader())
            if(response){
                return response.data.resp
            }else{
                return 
            }
        } catch (error) {
            return
        }
    }
)
const teachingsSlice = createSlice({
    name:'teachings',
    initialState,
    reducers:{
        loadTeachings(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getTeachings.pending]:(state,action)=>{state.status='pending'},
        [getTeachings.fulfilled]:(state,action)=>{state.items=action.payload;state.status = 'fulfilled'},
        [getTeachings.rejected]:(state,action)=>{state.status = 'rejected'}
    }
})

export const{loadTeachings} = teachingsSlice.actions;
export default teachingsSlice.reducer;